import './App.css';
import Minter from './Minter'
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import TermsConditions from './TermsConditions';

function App() {
  return (
    <div className="App">
     <Router>
      <Routes>
          {/* This route is for home component 
          with exact path "/", in component props 
          we passes the imported component*/}
            <Route path="/home" element={<Minter/>} />
            <Route path="/" element={<Minter/>} />
            <Route path="/terms" element={<TermsConditions/>} />
          </Routes>
          </Router>
    
    </div>
  );
}

export default App;
