import React from "react";
import { Link } from "react-router-dom";
import AppLogo from './assets/images/logobig.png';
const TermsConditions = (props) => {
	return (
		<div class="body">
			  <div class="metaportal_fn_mobnav">
        <div class="mob_top">
       
        </div>
        <div class="mob_mid">
          <div class="logo">
          <Link to="/Home"><img src={AppLogo} alt="" /></Link>
          </div>
        </div>
   </div>
      <div id="header">
        <div class="header">
          <div class="header_in">
            <div class="trigger_logo">
              <div class="trigger">
                <span></span>
              </div>
              <div class="logo">
                <Link to="/Home"><img src={AppLogo} alt="" /></Link>
              </div>
            </div>
            
          </div>
        </div>
      </div>
			<div class="metaportal_fn_main">
				<div class="metaportal_fn_content">
					<div class="metaportal_fn_pagetitle">
						<div class="container small">
							<div class="pagetitle">
								<h3 class="fn__maintitle big" data-text="Terms &amp; Conditions" data-align="center">Terms &amp; Conditions</h3>
								
							</div>
						</div>
					</div>

					<div class="fn_cs_section_divider">
						<div class="divider">
							<span class="short"></span>
							<span class="long"></span>
							<span class="short"></span>
						</div>
					</div>

					<div class="metaportal_fn_privacy">
						<div class="container small">


							<h3 class="fn__maintitle" data-text="Ownership of Site; Agreement to Terms of Use">Ownership of Site; Agreement to Terms of Use</h3>
							<div class="fn_cs_divider">
								<div class="divider">
									<span></span>
									<span></span>
								</div>
							</div>
							<p>
								Last Updated: November 2, 2022

								These terms and conditions ("Terms & Conditions") govern your use of the unknownwalkerz.xyz website, including your purchase of any Unknown Walkerz NFTs; by using this website or attempting to purchase an Unknown Walkerz NFT, you accept these Terms & Conditions in full. If you disagree with these Terms & Conditions or any part of these Terms & Conditions, you must not use the unknownwalkerz.xyz website or attempt to purchase any Unknown Walkerz NFT.
							</p>


							<h3 class="fn__maintitle" data-text="Content">Content</h3>
							<div class="fn_cs_divider">
								<div class="divider">
									<span></span>
									<span></span>
								</div>
							</div>



							<h3 class="fn__maintitle" data-text="1.Definitions">1.Definitions</h3>
							<div class="fn_cs_divider">
								<div class="divider">
									<span></span>
									<span></span>
								</div>
							</div>
							<p>
								“Art” means any art, designs, drawings, traits, layers, and other design elements that may be associated with a Licensed NFT that you own.

								<br />“Content” means text, images, audio material, video material, audio-visual material, or other forms of media.

								<br />“Licensed NFT” Means a non-fungible token that you Own and was originally minted on this Site. “NFT” means any blockchain-tracked, non-fungible token.

								<br />“NFT Trading Platform” means a secure marketplace where NFTs are sold, transferred, and recorded on the applicable blockchain and such transactions can be proven by the applicable blockchain.

								<br />“Own” or “Ownership” means, with respect to a Licensed NFT, a Licensed NFT that you have purchased through the Website or otherwise rightfully acquired from a legitimate source, where proof of purchase was recorded on the applicable blockchain and ownership of the Licensed NFT can be proven.

								<br />“Owner” means someone who has rightful and legal Ownership of a Licensed NFT.

							</p>

							<h3 class="fn__maintitle" data-text="2.Your Acceptance">2.Your Acceptance</h3>
							<div class="fn_cs_divider">
								<div class="divider">
									<span></span>
									<span></span>
								</div>
							</div>
							<p>
								These Terms & Conditions constitute an agreement ("Agreement") between Random Character Collective, LLC ("IOR"), the owner and operator of the Unknown Walkerz website, https://unknownwalkerz.xyz (the “Site” or “Website”), and you (“you”, “your” or “user(s)”), a user of the Site.

								Throughout this Agreement, the words “IOR,” “us,” “we,” and “our,” refer to our group, Unknown Walkerz.

								<br />BY ACCESSING THIS WEBSITE, YOU AGREE TO AND ACCEPT THESE TERMS AND CONDITIONS IN FULL AND WITHOUT RESERVATION. IF YOU DISAGREE WITH THESE TERMS & CONDITIONS, YOU MAY NOT USE THIS WEBSITE OR ATTEMPT TO PURCHASE ANY Unknown Walkerz NFTS.

								<br />We may amend this Agreement at any time so please come back and review these Terms & Conditions often.
							</p>

							<h3 class="fn__maintitle" data-text="3. Unknown Walkerz Ownership">3. Unknown Walkerz Ownership</h3>
							<div class="fn_cs_divider">
								<div class="divider">
									<span></span>
									<span></span>
								</div>
							</div>
							<p>
								Unless otherwise stated,TMAG and/or its licensors and affiliates own the intellectual property rights published on this website, including all text, data, graphics, photographs, images, audio, video, trademarks, service marks, trade names and other information, visual or other digital material, software (including source and object codes) and all other content or any description available on the Site or available via a link from Site to a page created byTMAG on another website (collectively, the "IOR Content"). TheTMAG Content is the sole property ofTMAG and/or its licensors, affiliates, or third-party service providers.

								<br />You acknowledge and agree thatTMAG and/or its licensors and affiliates own all legal rights, title, interest in the Art associated with any Licensed NFTs, and all intellectual property rights, including but not limited to, trademark and copyright rights, therein. Any rights provided to you upon purchase or ownership of a Licensed NFT are limited to those expressly stated herein.TMAG reserves all rights and ownership to the Licensed NFTs and Art not specifically granted to the User in this Agreement.
							</p>
							<h3 class="fn__maintitle" data-text="4. Purchase">4. Purchase</h3>
							<div class="fn_cs_divider">
								<div class="divider">
									<span></span>
									<span></span>
								</div>
							</div>
							<p>
								Any payments of financial transactions that you conduct or engage in through the Site are final. You acknowledge and understand that the Site cannot and will not reverse any payments or transactions. All purchases of Licensed NFTs, as well as any associated charges, are non-refundable.

								<br />You shall be responsible for all applicable taxes including any sales or compensating use tax or equivalent tax wherever such tax may arise.

								<br />The user accepts and acknowledges thatTMAG and its Affiliates will not be responsible for any communication failures, disruptions, distortions, delays, or any other errors that may arise when you attempt to purchase any Licensed NFTS.
							</p>
							<h3 class="fn__maintitle" data-text="5. User Ownership">5. User Ownership</h3>
							<div class="fn_cs_divider">
								<div class="divider">
									<span></span>
									<span></span>
								</div>
							</div>
							<p>
								Upon you assuming Ownership of a Licensed NFT and accepting the Terms and Conditions of this Agreement,TMAG shall grant you a non-exclusive, worldwide, royalty-free license to 1) use, display, or otherwise enjoy the Art of your Licensed NFT for your own personal use; 2) commercialize your Licensed NFT by producing and selling physical merchandise that portrays the Licensed NFT’s underlying Art in its entirety; 3) sell or transfer your Licensed NFTs to another party pursuant to Section 7 of this Agreement; and 4) use your Licensed NFT as part of a third-party website or application which permits the inclusion, involvement, and/or participation for your Licensed NFTs, provided that the website or application cryptographically permits and verifies each NFT owner’s rights and ownership to display the Art and the website or application ensures only the actual owner can display the Art.

								<br />IOR may provide you, the Owner of a Licensed NFT, a cropped version of the character portrayed in the Licensed NFT (“Licensed NFT Crop”). In the event that you are provided a Licensed NFT Crop,TMAG grants you a non-exclusive, worldwide, royalty-free license to use, display, or otherwise enjoy the Licensed NFT Crop solely for your own personal use and not for any commercial use of any kind.

								<br />You understand and agree that these rights are licensed solely to the rightful and legal Owner of the Licensed NFT. Your licensed rights to the Licensed NFT will terminate upon the rightful and legal sale or transfer of your Licensed NFT in accordance with Sections 7 and 8 of this Agreement and the new Owner, upon their acceptance of the Terms and Conditions of this Agreement, shall receive these licensed rights.
							</p>
							<h3 class="fn__maintitle" data-text="6. Restrictions to Use">6. Restrictions to Use</h3>
							<div class="fn_cs_divider">
								<div class="divider">
									<span></span>
									<span></span>
								</div>
							</div>
							<p>
								You understand and agree that any physical merchandise that you produce must incorporate every element and trait of your Licensed NFT in full. The individual layers and traits of the Licensed NFTs are not owned by you and are the sole property ofTMAG and/or its licensors and affiliates. Such commercialization rights are limited to the Licensed NFTs that you own, and those rights do not extend to or include the Licensed NFT Crops thatTMAG may provide to you.

								<br />You further understand and agree that this license does not permit the ability to create any digital merchandise. The creation and minting of any new NFTs which are derivatives of your Licensed NFTs are expressly prohibited.

								<br />You understand and acknowledge that you may not, nor permit any third party to do or attempt to do the foregoing without the express prior written consent from IOR; (1) modify the Art in your Licensed NFT or any applicable Licensed NFT Crop in any way, including, without limitation, the shapes, designs, drawings, attributes, color schemes, or design elements of the Licensed NFT; (2) produce any merchandise in connection with your Licensed NFT that depicts lewd behavior, illegality, hatred, intolerance, cruelty, vulgarity, pornographic or other “adult-only” material, discrimination, or otherwise harmful material to the Art and Unknown Walkerz’ Brand; (3) or attempt to trademark, copyright, or otherwise attempt to acquire additional intellectual property rights in your Licensed NFT or any applicable Licensed NFT Crop.
							</p>
							<h3 class="fn__maintitle" data-text="7. Transfer">7. Transfer</h3>
							<div class="fn_cs_divider">
								<div class="divider">
									<span></span>
									<span></span>
								</div>
							</div>
							<p>
								You have the limited right to transfer or sell your Licensed NFTs on an NFT Trading Platform, provided that the transferee or purchaser understands and accepts the terms of this Agreement and the terms of the NFT trading platform applicable Terms & Conditions and that prior to the transfer, you have not breached this Agreement or the Terms & Conditions of the NFT Trading Platform.

								<br />IOR is not responsible for any transactions between you and a third party, including using any NFT Trading Platform to transfer your Licensed NFTs.TMAG shall have no responsibilities or liability with respect to any transfer you enact on any of these NFT Trading Platforms.
							</p>
							<h3 class="fn__maintitle" data-text="8. Termination of License">8. Termination of License</h3>
							<div class="fn_cs_divider">
								<div class="divider">
									<span></span>
									<span></span>
								</div>
							</div>
							<p>
								The licensed rights granted to you in Section 5 of this Agreement shall automatically terminate if you perform any of the following actions: 1) you sell, trade, donate, or otherwise transfer your Licensed NFT in any manner; (2) you breach any terms of this Agreement or any Terms & Conditions for the designated NFT Trading Platform; or (3) you engage in or initiate any legal actions againstTMAG and/or any of their affiliates, and each of their respective officers, directors, members, affiliates, agents, or employees.

								<br />Upon termination of your licensed rights, you must immediately cease and desist all activities and rights granted to you from Section 5. This includes ceasing all activities involving the Licensed NFT for your own personal use and creating or selling physical merchandise that incorporates the Art of the Licensed NFT.
							</p>
							<h3 class="fn__maintitle" data-text="9. Assumption of Risk">9. Assumption of Risk</h3>
							<div class="fn_cs_divider">
								<div class="divider">
									<span></span>
									<span></span>
								</div>
							</div>
							<p>
								You understand and agree that the Licensed NFTs are made solely for entertainment purposes only. You agree and understand that that: (1) the market and prices for a blockchain asset are extremely volatile and subjective and collectible blockchain assets, such as the Licensed NFTs, have no inherent or intrinsic value, and fluctuations in the price of such blockchain assets could materially and adversely affect the price and value of your Licensed NFTs; (2) there are risks involved with using internet-based currency and asset, including, but not limited to, risk of hardware, software, internet connection failure, risk of malicious software, and risk that unauthorized parties may gain access to your personal information including such information and assets contained in your digital wallet or elsewhere; (3) internet-based currency and assets are not currently regulated by any regulatory regimes, and new regulations or policies that may materially affect the value of any Licensed NFTs; (4) there is an inherent risk that you may lose access due to loss of private keys, custodial error, or even purchaser error; (5) there are risks related to taxation; and (6)TMAG does not make any guarantees or representations about the availability of the Licensed NFTs or the art or that they will host the Licensed NFTs or the Art in any specific location for any specific period of time.

								<br />You agree that you have received sufficient information to make an informed decision regarding the decision to purchase or otherwise obtain the Licensed NFTs and that you understand and agree that you are solely responsible for determining the value, nature, and appropriateness of the above risks for yourself.
							</p>
							<h3 class="fn__maintitle" data-text="10. Limitation of Liability">10. Limitation of Liability</h3>
							<div class="fn_cs_divider">
								<div class="divider">
									<span></span>
									<span></span>
								</div>
							</div>
							<p>
								ALL LICENSED NFTS ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED.TMAG AND ITS AFFILIATES HEREBY DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. IN NO EVENT SHALLTMAG BE LIABLE TO YOU FOR ANY INDIRECT, EXTRAORDINARY, EXEMPLARY, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES (INCLUDING LOSS OF DATA, GOODWILL, WORK STOPPAGE, DIMINUTION OF VALUE OR ANY OTHER INTANGIBLE LOSS, TECHNOLOGY FAILURE, OR MALFUNCTION, REVENUE, PROFITS, USE OR OTHER ECONOMIC ADVANTAGE RELATED TO ANY LICENSED NFT OR OTHERWISE) HOWEVER ARISING, EVEN IFTMAG KNOWS THERE IS A POSSIBILITY OF SUCH DAMAGE.
							</p>
							<h3 class="fn__maintitle" data-text="11. Unauthorized Conduct">11. Unauthorized Conduct</h3>
							<div class="fn_cs_divider">
								<div class="divider">
									<span></span>
									<span></span>
								</div>
							</div>
							<p>

								When accessing or using our Site, you are solely responsible for your actions, and you agree to abide by the following rules of conduct:

								<br />You agree not to copy, distribute or disclose any part of the Site in any medium, including without limitation by any automated or non-automated “scraping;”
								<br />You agree not to attempt to interfere with, compromise the system integrity or security, or decipher any transmissions to or from the servers running the Site;
								<br />You agree not to use any robot, spider, crawler, scraper or other automated means or interface not provided by us to access the Site or to extract or export data collected through the Site;
								<br />You agree not to take any action that imposes, or may impose at our sole discretion, an unreasonable or disproportionately large load on our infrastructure; You agree that you will not hold IOR, any of its affiliates, or its third-party providers responsible for your use of the Site;
								<br />You agree not to violate any requirements, procedures, policies, or regulations of networks connected to IOR;
								<br />You agree not to interfere with or disrupt the Site;
								<br />You agree not to post anything contrary to our public image, goodwill, or reputation;
								<br />You agree to not violate any US federal laws, state laws, or local laws while using the Site; and
								<br />You agree not to use the Site in any way that is: misleading, unlawful, defamatory, obscene, invasive, threatening, harmful, or harassing.
								<br />If you are discovered to be undertaking any of the aforementioned actions your privileges to use our Site may, at our discretion, be terminated or suspended.TMAG reserves the right to suspend or terminate your access at any time without notice or explanation.
							</p>
							<h3 class="fn__maintitle" data-text="12. No Joint Venture">12. No Joint Venture</h3>
							<div class="fn_cs_divider">
								<div class="divider">
									<span></span>
									<span></span>
								</div>
							</div>
							<p>
								This Agreement and any action related to this Agreement shall be governed by the laws in force in the State of California and US Federal law. Foreign laws do not apply. The offer and acceptance of this contract are deemed to have occurred in the State of California.
							</p>
							<h3 class="fn__maintitle" data-text="13. Age">13. Age</h3>
							<div class="fn_cs_divider">
								<div class="divider">
									<span></span>
									<span></span>
								</div>
							</div>
							<p>
								All users who access the Site or buy Licensed NFTs must be 18 years of age or older.
							</p>
							<h3 class="fn__maintitle" data-text="14. No Joint Venture">14. No Joint Venture</h3>
							<div class="fn_cs_divider">
								<div class="divider">
									<span></span>
									<span></span>
								</div>
							</div>
							<p>
								This Agreement shall not constitute a joint venture, partnership, employment, or agency relationship between the parties.
							</p>
							<h3 class="fn__maintitle" data-text="15. Choice of Law & Venue">15. Choice of Law & Venue</h3>
							<div class="fn_cs_divider">
								<div class="divider">
									<span></span>
									<span></span>
								</div>
							</div>
							<p>
								This Agreement and any action related to this Agreement shall be governed by the laws in force in the State of California and US Federal law. Foreign laws do not apply. The offer and acceptance of this contract are deemed to have occurred in the State of California.
							</p>
							<h3 class="fn__maintitle" data-text="15. Amendments">16. Amendments</h3>
							<div class="fn_cs_divider">
								<div class="divider">
									<span></span>
									<span></span>
								</div>
							</div>
							<p>
								We may amend this Agreement from time to time.  When we amend this Agreement, we will update this page and indicate the date that it was last modified, or we may email you.  You may refuse to agree to the amendments, but if you do, you must immediately cease using our Site. Amended Terms & Conditions will apply to the use of this Site from the date of publication of the amended Terms & Conditions of this Site.
							</p>



							<footer id="footer">
								<div class="container">
									<div class="footer">
										<div class="left_part">
											<p>Unknown Walkerz 2022</p>
										</div>
										<div class="right_part">
											<ul>
												<li><a class="creative_link" href="index.html">Home</a></li>
											</ul>
										</div>
									</div>
								</div>
							</footer>


						</div>


					</div>

				</div>
			</div>
		</div>
	);
};
export default TermsConditions;